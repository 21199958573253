import WebApiService from "@/services/webapi.service.js";

const state = {
    categorie: null
}

const getters = {
    getCategorie: (state) => {
        return state.categorie ?? JSON.parse(localStorage.getItem("categorie-eagle"));
    },

    getCategoria: (state) => (codiceCategoria) => {
        let categorie = state.categorie ?? JSON.parse(localStorage.getItem("categorie-eagle"));
        if(categorie){
            let categoria = categorie.find(item =>{ return item.CodiceCategoria === codiceCategoria;});
            if(categoria){
                return categoria;
            }else{
                return " - ";
            }
        }else{
            return " - ";
        }
    }
}

const mutations = {
    setCategorie: (state, categorie) => {
        state.categorie = categorie;
        localStorage.setItem('categorie-eagle', JSON.stringify(state.categorie));
    }
}

const actions = {
    getCategorie: ({commit},data) =>{
        return WebApiService.get("categorie/GetCategorie", data.Token).then(
            (data) =>{
                if(data.StatusCode !== 200){
                    return [false,data.Message];
                }else{
                    commit("setCategorie", data.Data.map(
                        o => {return Object.assign(o)}
                    ));
                    return [true,""];
                }
            },
            error =>{
                return [false,""];
            }
        ).catch(
            (error) => {return [false, error]}
        );
    }
}

export default {
    namespeced: true,
    state,
    getters,
    mutations,
    actions
}
import axios from 'axios'
import ApiResponse from "@/models/Api/apiResponse.model.js";

window.Buffer = window.Buffer || require('buffer').Buffer;

const API_URL = process.env.VUE_APP_API_URL;
const KEY = process.env.VUE_APP_APIKEY;

class WebApiService {

    async login(url, apikey, loginData) {
        return axios({
            method: "post",
            url: API_URL + url,
            headers: {
            'Authorization': apikey,
            'Content-Type': 'application/json'
            },
            data: Buffer.from(JSON.stringify(loginData)).toString("base64")
        })
        .then(response => {
            return Object.assign(new ApiResponse(), response.data);
        });
    }
    async cambioPassword(token, campioPasswordData){
        return axios({
            url: API_URL + 'Account/CambioPassword',
            method: "post",          
            headers:{
                Authorization: 'Bearer '+ token,
                'Content-Type': 'application/json'
            },
            data: campioPasswordData
        })
        .then(
            data =>  { 
                return data;
            }
        ) 
        .catch(error => {       
            return error.response;
        });
    }
    async get(url, token, params) {
        return axios({
            method: 'get',
            url: API_URL + url,
            params: params,
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).then(
        response => {        
            return Object.assign(new ApiResponse(), response.data);
        }
        )
        .catch(error => {           
            return new ApiResponse(error.response.status, null, error.response.statusText);
        });
    }
    async getWApiKey(url, token, params) {
        return axios({
            method: 'get',
            url: API_URL + url,
            params: params,
            headers: {
                Authorization: KEY,
                'Content-Type': 'application/json'
            }
        }).then(
        response => {        
            return Object.assign(new ApiResponse(), response.data);
        }
        )
        .catch(error => {           
            return new ApiResponse(error.response.status, null, error.response.statusText);
        });
    }
    async getWLang(url, token, params, lang) {
        return axios({
            method: 'get',
            url: API_URL + url,
            params: params,
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
                'CodiceLingua' : lang
            }
        }).then(
            response => {        
                return Object.assign(new ApiResponse(), response.data);
            }
        )
        .catch(error => {           
            return new ApiResponse(error.response.status, null, error.response.statusText);
        });
    }
    async getWLangApiKey(url, token, params,codLang) {
        return axios({
            method: 'get',
            url: API_URL + url,
            params: params,
            headers: {
                Authorization: KEY,
                'Content-Type': 'application/json',
                'CodiceLingua' : codLang
            }
        }).then(
        response => {        
            return Object.assign(new ApiResponse(), response.data);
        }
        )
        .catch(error => {           
            return new ApiResponse(error.response.status, null, error.response.statusText);
        });
    }
    async getImage(url, token, params) {
        return axios({
            method: 'get',
            url: API_URL + url,
            params: params,
            responseType:'blob',
            headers: {
                Authorization: 'Bearer ' + token  
            }
        }).then(
            response => { 
                return  Object.assign(new ApiResponse(), response);
        })
        .catch(error => {           
            return new ApiResponse(error.response.status, null, error.response.statusText);
        });
    }
    async post(url, token, data, params = null) {
        return axios({
            method: 'post',
            url: API_URL + url,
            params: params,
            data: data,
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        }).then(
        response => {     
            return Object.assign(new ApiResponse(), response.data);
        })
        .catch(error => {           
            return new ApiResponse(error.response.status, null, error.response.statusText);
        });
    }
    async postWLang(url, token, data, params = null, codLang) {
        return axios({
            method: 'post',
            url: API_URL + url,
            params: params,
            data: data,
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json',
                'CodiceLingua' : codLang
            }
        }).then(
        response => {     
            return Object.assign(new ApiResponse(), response.data);
        })
        .catch(error => {           
        return new ApiResponse(error.response.status, null, error.response.statusText);
        });
    }
    async postWLangApiKey(url, apikey = KEY, data, params = null, codLang) {
        return axios({
            method: 'post',
            url: API_URL + url,
            params: params,
            data: data,
            headers: {
                Authorization: KEY,
                'Content-Type': 'application/json',
                'CodiceLingua' : codLang
            }
        }).then(
        response => {     
            return Object.assign(new ApiResponse(), response.data);
        })
        .catch(error => {           
            return new ApiResponse(error.response.status, null, error.response.statusText);
        });
    }
    async getRisorse(token, codShop){     
        return axios({
            method: 'get',
            url: API_URL + 'risorse/GetRisorse',         
            headers:{
                Authorization: KEY,
                'Content-Type': 'application/json'
            }            
        }).then(
            data =>  {               
                if(data.status == 401){
                    return null;
                }
                else{
                    return data.data;
                }               
            }
        )
        .catch(error => {return error;});            
    }
}


export default new WebApiService();
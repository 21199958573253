import WebApiService from "@/services/webapi.service.js";
import Utente from "@/models/Utente/utente.model.js";
import LoginData from "@/models/Utente/loginData.model.js";
import IndirizziSpedizione from "@/models/Utente/indirizziSpedizione.model";
import DatiLoginUtente from "@/models/Utente/datiLoginUtente.model.js";
import MetodiDiPagamento from "@/models/Utente/metodiDiPagamento.model";

const state = {
    utente: null,
    lingua: null
}

const getters = {
    getUtente: (state) => {
        let utente = state.utente;
        if(!utente){
            let user = localStorage.getItem("utente-Eagle") ;
            if(user){
                utente = JSON.parse(new Buffer.from( user, 'base64').toString('ascii'));
            }
        }      
        return utente;
    },
    getNomeUtente: (state) => {
        let utente = state.utente;
        if(!utente){
            utente = JSON.parse(new Buffer.from(localStorage.getItem("utente-Eagle") ?? "", 'base64').toString('ascii'));
        }      
        if(utente.DatiUtente)
            return utente.DatiUtente.Nome;
        
        return "";
    },
    getIndirSped: (state) =>(k2Indir) => {
        let utente = state.utente;
        if(!utente){
            utente = JSON.parse(new Buffer.from(localStorage.getItem("utente-Eagle") ?? " ", 'base64').toString('ascii'));
        }   
        if(utente.DatiUtente && utente.IndirizziSpedizione){
            let indirizzoTxt = "";
            let indirizzo = utente.IndirizziSpedizione.find((item =>{ return item.K2AnagenIndir == k2Indir }));
            let prov = null;
            if(indirizzo != null && indirizzo.CodiceProvincia != null){
                prov =" ("+indirizzo.CodiceProvincia+ ")";
                indirizzoTxt =  indirizzo.Indirizzo+", "+(indirizzo.Cap ?? " " )+" "+(indirizzo.Citta ?? " ")+ (prov ?? " ");
            }
            return indirizzoTxt;
        }
        return "";
    },
    isLoggedIn:(state) =>{
        let utente = localStorage.getItem("utente-Eagle");
        if(utente != null || state.utente != null){
            return true;
        }
        return false;
    },
    getLinguaUtente: (state) => {
        let utente = state.utente;
        if(!utente){
            utente = JSON.parse(new Buffer.from(localStorage.getItem("utente-Eagle") ?? " ", 'base64').toString('ascii'));
        }
        if(utente.DatiLoginUtente && utente.DatiLoginUtente.CodLang){
            return utente.DatiLoginUtente.CodLang;
        }else{
            return "-";
        }
    },
    getLinguaNoLogin: (state) =>{
        let lingua = state.lingua;
        if(lingua){
            let lang = localStorage.getItem("lingua");
            if(lang != null){
                lingua = JSON.parse(new Buffer.from(lang ?? " ", 'base64').toString('ascii'));
            }else{
                return "IT";
            }
        }
        if(lingua){
            return lingua;
        }else{
            return "IT";
        }
    }
}

const mutations = {

    setUtente: (state, utente) => {
        utente.DatiLoginUtente.CodLang = "IT";
        state.utente = utente;
        localStorage.setItem("utente-Eagle", Buffer.from(JSON.stringify(utente)).toString("base64"));        
    },
    setLangUtente: (state, data) =>{
        data.utente.DatiLoginUtente.CodLang = data.CodiceLingua;
        state.utente = data.utente;
        localStorage.setItem("utente-Eagle", Buffer.from(JSON.stringify(state.utente)).toString("base64"));    
    },
    setLangNoLogin: (state,data) =>{
        state.lingua = data.CodiceLingua;
        localStorage.setItem("lingua", Buffer.from(JSON.stringify(state.lingua)).toString("base64"));     
    },
    setDettagliUtente: (state, dettagliUtente) => {   
        let utente = state.utente;
        if(!utente){
            utente = JSON.parse(new Buffer.from(localStorage.getItem("utente-Eagle"), 'base64').toString('ascii'));
        }        
        utente.DatiUtente = dettagliUtente;
        state.utente = utente;
        localStorage.setItem("utente-Eagle", Buffer.from(JSON.stringify(utente)).toString("base64"));     
    },
    setIndirizziSpedizione: (state, indirizzi) => {   
        let utente = state.utente;
        if(!utente){
            utente = JSON.parse(new Buffer.from(localStorage.getItem("utente-Eagle"), 'base64').toString('ascii'));
        }        
        utente.IndirizziSpedizione = indirizzi;
        state.utente = utente;
        localStorage.setItem("utente-Eagle", Buffer.from(JSON.stringify(utente)).toString("base64"));     
    },
    setMetodiDiPagamento: (state, metodi) => { 
        let utente = state.utente;
        if(!utente){
            utente = JSON.parse(new Buffer.from(localStorage.getItem("utente-Eagle"), 'base64').toString('ascii'));
        }        
        utente.MetodiDiPagamento = metodi;
        state.utente = utente;
        localStorage.setItem("utente-Eagle", Buffer.from(JSON.stringify(utente)).toString("base64"));     
    },
    resetUser: (state) =>{
        state.utente = null;
        localStorage.clear();
    }
}

const actions = {
    login: ({commit}, user) => {     
        let loginData = new LoginData(user.username, user.password, user.idCompany, user.idPortale);
        return WebApiService.login('account/login', user.apikey, loginData).then(
        (data) => {    
                if(data.StatusCode !== 200){
                    return [false, data.Message];
                }
                else{
                    let utente = new Utente();
                    Object.assign(utente.DatiLoginUtente = new DatiLoginUtente(), data.Data);
                    commit("setUtente", utente);
                    return [true, ""];            
                }
            },
            error => {  
                return [false, error];
            }
        )
        .catch(
            error => {return [false, error];}
        );
    },
    SetIndirSpedAPi: ({commit}, data) => {
        return WebApiService.get("Utente/GetIndirizziSped", data.Token).then(
            (data) =>{
                if(data) {
                    let Indir = data.Data.map(o => {
                        return Object.assign(new IndirizziSpedizione(), o)
                    });
                    commit("setIndirizziSpedizione",Indir);
                }
            }
        ).catch(
            error => {return [false, error];}
        );
    },
    SetMetodiPagAPi: ({commit}, data) => {
        return WebApiService.get("Risorse/GetMetodiDiPagemento", data.Token).then(
            (data) =>{
                if(data) {
                    let metodi = data.Data.map(o => {
                        return Object.assign(new MetodiDiPagamento(), o)
                    });
                    commit("setMetodiDiPagamento",metodi);
                }
            }
        ).catch(
            error => {return [false, error];}
        );
    },
    GetDatiUtenteApi: ({commit},data) =>{
        return WebApiService.get("Utente/GetDatiUtente", data.Token).then(
            (data) =>{
                if(data) {
                    let userData  = {};
                    Object.assign(userData, data.Data);
                    commit("setDettagliUtente",userData);
                }
            }
        ).catch(
            error => {return [false, error];}
        );
    },
    CheckLogin:({commit},data) =>{
        return WebApiService.get("Utente/CheckLogin",data.Token).then(
            (data) =>{
                if(data) {
                    if(data.StatusCode == 401){
                        commit("resetUser");
                        return false;
                    }
                    return true;
                    //return true;
                }else{
                    commit("resetUser");
                    return false;
                }
            }
        );
    },
    resetUtente: ({commit}) =>{
        return commit("resetUser");
    },
    SetLangUtente: ({commit},data) =>{
        return commit("setLangUtente",data);
    },
    SetLangNoLogin: ({commit},data) =>{
        return commit("setLangNoLogin", data);
    }
}

export default{
    namespeced: true,
    state,
    getters,
    mutations,
    actions
}
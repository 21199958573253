export default class PaginaWidget{
    IdWidget;
    IdPagina;
    Posizione;
    TipoWidget;
    Titolo;
    Testo;
    UrlImmagine;
    ColoreSfondo;  
    constructor(){}
}
import WebApiService from "@/services/webapi.service.js";

const state = {
    ordini: null,
    ordiniAll:null
}

const getters = {
    getOrdini: (state) => {
        return state.ordini ?? JSON.parse(localStorage.getItem("ordini-eagle"));
    },
    getAllOrdini: (state) => {
        return state.ordiniAll ?? JSON.parse(localStorage.getItem("ordiniAll-eagle"));
    },

    getOrdine: (state) => (codiceOrdine) => {
        let ordini = state.ordini ?? JSON.parse(localStorage.getItem("ordini-eagle"));
        if(ordini){
            let ordine = ordini.find(item =>{ return item.IdOrdine === codiceOrdine;});
            if(ordine){
                return ordine;
            }else{
                return " - ";
            }
        }else{
            return " - ";
        }
    }
}

const mutations = {
    setOrdini: (state, ordini) => {
        state.ordini = ordini;
        localStorage.setItem('ordini-eagle', JSON.stringify(state.ordini));
    },
    setAllOrdini: (state, ordini) => {
        state.ordiniAll = ordini;
        localStorage.setItem('ordiniAll-eagle', JSON.stringify(state.ordiniAll));
    },
}

const actions = {
    GetTestataOrdini: ({commit},data) =>{
        return WebApiService.get("Ordini/GetOrdiniTesta", data.Token).then(
            (data) =>{
                if(data.StatusCode !== 200){
                    return [false,data.Message];
                }else{
                    let ordini =[];
                    Object.assign(ordini,data.Data);
                    commit("setOrdini", ordini);
                    return [true,""];
                }
            },
            error =>{
                return [false,""];
            }
        ).catch(
            (error) => {return [false, error]}
        );
    },
    GetAllOrdini: ({commit},data) =>{
        return WebApiService.get("Ordini/GetOrdiniAll", data.Token).then(
            (data) =>{
                if(data.StatusCode !== 200){
                    return [false,data.Message];
                }else{
                    // let ordini =[];
                    // Object.assign(ordini,data.Data);
                    commit("setAllOrdini", data.Data);
                    return [true,""];
                }
            },
            error =>{
                return [false,""];
            }
        ).catch(
            (error) => {return [false, error]}
        );
    }
}

export default {
    namespeced: true,
    state,
    getters,
    mutations,
    actions
}
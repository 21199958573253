const state = {
    startIndex: 0
}

const getters = {
    getStartIndex: (state) => {
        return state.startIndex ?? JSON.parse(localStorage.getItem("startIndex-eagle"));
    }
}

const mutations = {
    setStartIndex: (state ,newIndex) =>{
        state.startIndex += newIndex;
        localStorage.setItem('startIndex-eagle', JSON.stringify(state.startIndex));
    },
    resetStartIndex: (state) =>{
        state.startIndex = 0 ;
        localStorage.setItem('startIndex-eagle', JSON.stringify(state.startIndex));
    }
}

export default {
    namespeced: true,
    state,
    getters,
    mutations
}
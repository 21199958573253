import WebApiService from "@/services/webapi.service.js";
import Pagina from "@/models/Website/pagina.model.js";
import PaginaWidget from "@/models/Website/PaginaWidget.model.js";

const state = {
    pagine: null
}

const getters = {
    getPagine: (state) => {
        return state.pagine ?? JSON.parse(localStorage.getItem("pagine"));
    },

    getPagina: (state) => (codicePagina) => {
        let pagine = state.pagine ?? JSON.parse(localStorage.getItem("pagine"));
        if(pagine){
            let pagina = pagine.find(item =>{ return item.IdPagina === codicePagina});
            if(pagina){
                return pagina;
            }else{
                return " - ";
            }
        }else{
            return " - ";
        }
    }
}

const mutations = {
    setPagine: (state, pagine) => {
        state.pagine = pagine;
        localStorage.setItem('pagine', JSON.stringify(state.pagine));
    }
}

const actions = {
    GetPagineApi: ({commit},data) =>{
        return WebApiService.get("pagine/Getpagine", data.Token).then(
            (data) =>{
                if(data.StatusCode !== 200){
                    return [false,data.Message];
                }else{
                    commit("setPagine", data.Data.map(
                        o => {return Object.assign(new Pagina(),o)}
                    ));
                    return [true,""];
                }
            },
            error =>{
                return [false,""];
            }
        ).catch(
            (error) => {return [false, error]}
        );
    },
    GetWidgetPaginaApi: ({commit},data) =>{
        return WebApiService.get("pagine/GetWidgetById", data.Token).then(
            (data) =>{
                if(data.StatusCode !== 200){
                    return [false,data.Message];
                }else{
                    commit("setPagine", data.Data.map(
                        o => {return Object.assign(new PaginaWidget(),o)}
                    ));
                    return [true,""];
                }
            },
            error =>{
                return [false,""];
            }
        ).catch(
            (error) => {return [false, error]}
        );
    }
}

export default {
    namespeced: true,
    state,
    getters,
    mutations,
    actions
}
export default class LoginData{
    username;
    password;
    idCompany;
    idPortale;

    constructor(username, password, idCompany, idPortale){
        this.username = username;
        this.password = password;
        this.idCompany = idCompany;
        this.idPortale = idPortale;
    }
}
export default class CarrelloPayload{
    IdAnaart;
    CodiceIva;
    CodiceLingua;
    Quantita;

    setDati(
        IdAnaart,
        codiceIva,
        codiceLingua,
        quantita
    ){
        this.IdAnaart = IdAnaart;
        this.CodiceIva = codiceIva;
        this.CodiceLingua = codiceLingua;
        this.Quantita = quantita;
    }
    constructor(){}
}
import moment from "moment";
import CarrelloPayload from "@/models/Carrello/carrelloPayload.model.js";
import Carrello from "@/models/Carrello/carrello.model.js";
import WebApiService from "@/services/webapi.service.js";

const state = {
    carrello: null,
    carrelloTmp: null,
    numeroArticoli: null,
    prezzoTotale: null
}

const getters = {
    getCarrello: (state) => {
        return state.carrello ?? JSON.parse(localStorage.getItem("carrello"));
    },
    getCarrelloTmp: (state) => {
        return state.carrelloTmp;
    },
    getNumeroRighe: (state) => {
        let carrello = state.carrello;
        if(carrello != null && carrello.Righe != null){
            return carrello.Righe.length;
        }else{
            return 0;
        }
    },
    getInfoCarrello: (state) => {
        let infoCarrello = {
            numeroArticoli: state.numeroArticoli ?? 0,
            prezzoTotale: state.prezzoTotale ?? 0
        };
        return infoCarrello;

    }
}

const mutations = {
    updateCarrello: (state, carrello) => {       
        state.carrello = carrello;
        localStorage.setItem('carrello', JSON.stringify(state.carrello));
    },
    ChangeArts: (state, data) => {
        let currentCarrello = null;
        currentCarrello = state.carrelloTmp;
        if(data.Quantita > 0){
            if(currentCarrello == null){
                currentCarrello = new Carrello();
                currentCarrello.Testata.DataCreazione = moment().format("YYYY-MM-DD HH:mm:ss");    
                currentCarrello.Testata.Valuta = 'eur';
            }
            let index = currentCarrello.Righe.findIndex(x =>{
                return (x.IdAnaart === data.IdAnaart && x.CodiceLingua === data.CodiceLingua)
            });
            let articolo = new CarrelloPayload(); 
            articolo.setDati(  
                data.IdAnaart,
                data.CodiceIva,
                data.CodiceLingua,
                data.Quantita
            );
            if(index != -1 ){
                currentCarrello.Righe.splice(index,1);
            }
            currentCarrello.Righe.push(articolo);
        }else{
            if(currentCarrello != null && currentCarrello.Righe != null){
                let index = currentCarrello.Righe.findIndex(x =>{
                    return (x.IdAnaart === data.IdAnaart && x.CodiceLingua === data.CodiceLingua)
                });
                currentCarrello.Righe.splice(index,1);
            }
        }
        state.carrelloTmp = currentCarrello;
    },
    RemoveCart: (state) => {
        state.carrello= null;
        localStorage.removeItem("carrello");
    },
    resetCarrelloTmp: (state) => {
        state.carrelloTmp = null;
    },
    setInfoCarrello: (state, data) => {
        data.NumeroArticoli != null ? state.numeroArticoli = data.NumeroArticoli : state.numeroArticoli = 0;
        data.ImportoCarrello != null ? state.prezzoTotale = data.ImportoCarrello : state.prezzoTotale = 0;
    }
}

const actions = {
    addItemToChart:({commit}, data) =>{
        commit("ChangeArts",data.data);
    },
    GetCarrelloApi:({commit},data) =>{
        return WebApiService.getWLang("Carrello/GetCarrello", data.Token).then(
            (data) =>{
                if(data){
                    let carrello = Object.assign(new Carrello(), data.Data);
                    commit("updateCarrello",carrello);
                }
            }
        );
    },
    resetCarrello:({commit}) => {
        commit("resetCarrelloTmp");
    },
    resetCarrelloSrv:({commit}) => {
        commit("RemoveCart");
    }
}

export default {
    namespeced: true,
    state,
    getters,
    mutations,
    actions
}
import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';

const Login = () => import(/* webpackChunkName: "login-chunk" */ '@/views/Login.vue');
const Default = () => import(/* webpackChunkName: "default-chunk" */ '@/views/Default.vue');
const Home = () => import(/* webpackChunkName: "home-chunk" */ '@/views/Home.vue');
const Profilo = () => import(/* webpackChunkName: "profile-chunk" */ '@/views/Profilo.vue');
const Carrello = () => import(/* webpackChunkName: "carrello-chunk" */ '@/views/Carrello.vue');
const Prodotti = () => import(/* webpackChunkName: "prodotti-chunk" */ '@/views/Prodotti.vue');
const SchedaProdotto = () => import(/* webpackChunkName: "scVar-chunk" */ '@/views/SchedaProdotto.vue');
const StoricoOrdiniDettaglio = () => import(/* webpackChunkName: "storOrd-chunk" */ '@/views/StoricoOrdiniDettaglio.vue');
const LoginPasswordRecovery = () => import(/* webpackChunkName: "loginPswRec-chunk" */ '@/views/LoginPasswordRecovery.vue');
const RisultatiRicerca = () => import(/* webpackChunkName: "risRic-chunk" */ '@/views/RisultatiRicerca.vue');
//const StoricoResoDettagli = () => import(/* webpackChunkName: "storResoDett-chunk" */ '@/views/StoricoResiDettaglio.vue');
const Pagina = () => import(/* webpackChunkName: "pagina-chunk" */ '@/views/Pagina.vue');
const Contatti = () => import(/* webpackChunkName: "contatti-chunk" */ '@/views/Contatti.vue')

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta:{
      requiresAuth: false
    }
  },
  {
    path: '/LoginPasswordRecovery',
    name: 'LoginPasswordRecovery',
    component: LoginPasswordRecovery,
    meta:{
      requiresAuth: false
    }
  },
  {
    path: '/default',
    name: "Default",
    component: Default,    
    children:[
      {
        path: '',
        name: "Home",
        component: Home,
        meta: {
          breadCrumbs:[
            {
              to: '',
              text: 'Homepage'
            }
          ],
          requiresAuth: true
        },
      },
      {
        path: '/profilo',
        name: "Profilo",
        component: Profilo,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'Home'
            },
            {
              to: '',
              text: 'Profilo'
            }
          ],
          requiresAuth: true
        },
      },
      {
        path: '/carrello',
        name: "Carrello",
        component: Carrello,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'Home'
            },
            {
              to: '',
              text: 'Carrello'
            }
          ],
          requiresAuth: true
        },
      },
      {
        path: 'prodotti/:idCategoria',
        name: "Prodotti",
        component: Prodotti,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'Home'
            },
            {
              to: '',
              text: 'Prodotti'
            }
          ],
          requiresAuth: true
        }
      },
      {
        path: 'prodotti/:idCategoria',
        name: "Prodotti",
        component: Prodotti,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'Home'
            },
            {
              to: '',
              text: 'Prodotti'
            }
          ],
          requiresAuth: true
        }
      },
      {
        path: 'scheda/:idProdotto&:Categoria',
        name: "SchedaProdotto",
        component: SchedaProdotto,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'Home'
            },
            {
              to: '',
              text: 'Scheda Prodotto'
            }
          ],
          requiresAuth: true
        }
      },
      {
        path: '/carrello',
        name: "Carrello",
        component: Carrello,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'Home'
            },
            {
              to: '',
              text: 'Carrello'
            }
          ],
          requiresAuth: true
        },
      },
      {
        path: 'dettagliOrdine/:idOrdine',
        name: "StoricoOrdiniDettaglio",
        component: StoricoOrdiniDettaglio,
        meta: {
          breadCrumbs:[
            {
              to: '/profilo',
              text: 'Ordini'
            },
            {
              to: '',
              text: 'Dettagli Ordine'
            }
          ],
          requiresAuth: true
        }
      },
      {
        path: 'CondizioniDiVendita/:idPagina',
        name: "CondizioniDiVendita",
        component: Pagina,
        meta: {
          breadCrumbs:[
            {
              to: '/profilo',
              text: 'Ordini'
            },
            {
              to: '',
              text: 'Condizioni di Vendita'
            }
          ],
          requiresAuth: true
        }
      },
      {
        path: 'Privacy/:idPagina',
        name: "Privacy",
        component: Pagina,
        meta: {
          breadCrumbs:[
            {
              to: '/profilo',
              text: 'Ordini'
            },
            {
              to: '',
              text: 'Condizioni di Vendita'
            }
          ],
          requiresAuth: true
        }
      },
      {
        path: 'CookiePolicy/:idPagina',
        name: "CookiePolicy",
        component: Pagina,
        meta: {
          breadCrumbs:[
            {
              to: '/profilo',
              text: 'Ordini'
            },
            {
              to: '',
              text: 'Condizioni di Vendita'
            }
          ],
          requiresAuth: true
        }
      },
      {
        path: 'SpedizioniResi/:idPagina',
        name: "SpedizioniResi",
        component: Pagina,
        meta: {
          breadCrumbs:[
            {
              to: '/profilo',
              text: 'Ordini'
            },
            {
              to: '',
              text: 'Spedizioni e Resi'
            }
          ],
          requiresAuth: true
        }
      },
      {
        path: 'ricerca/:searchKey',
        name: "RisultatiRicerca",
        component: RisultatiRicerca,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'Home'
            },
            {
              to: '',
              text: 'Risultati-Ricerca'
            }
          ],
          requiresAuth: true
        }
      },
      {
        path: '/contatti',
        name: "Contatti",
        component: Contatti,
        meta: {
          breadCrumbs:[
            {
              to: '/default',
              text: 'Home'
            },
            {
              to: '',
              text: 'Contatti'
            }
          ],
          requiresAuth: true
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === "Login" && store.getters.isLoggedIn) {
    next({ name: "Home" });
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next({
        name: "Login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router

import { createStore } from 'vuex';
import utente from './modules/utente.module';
import categorie from './modules/categorie.module';
import carrello from './modules/carrello.module';
import navigation from './modules/navigation.module';
import ordini from './modules/ordini.module';
import risorse from './modules/risorse.module';
import pagine from './modules/pagine.module';

export default createStore({
  modules:{
    utente,
    categorie,
    carrello,
    navigation,
    ordini,
    risorse,
    pagine
  }
})

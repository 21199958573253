import WebApiService from "@/services/webapi.service.js";
const state = {
    filtersArray: null
}

const getters = {
    getFiltersArray: (state) => {
        return state.filtersArray ?? JSON.parse(localStorage.getItem("filtersArray-eagle"));
    }
}

const mutations = {
    setFiltersArray: (state ,filtersArray) =>{
        state.filtersArray = filtersArray;
        localStorage.setItem('filtersArray-eagle', JSON.stringify(state.filtersArray));
    },
    resetFiltersArray: (state) =>{
        state.filtersArray = 0 ;
        localStorage.setItem('filtersArray-eagle', JSON.stringify(state.filtersArray));
    }
}

const actions = {
    GetRisorseApi: ({commit}, data) => {
        return WebApiService.get("Risorse/GetFilters", data.Token).then(
            (data) =>{
                if(data){
                    commit("setFiltersArray",data.Data);
                }
            }
        );
    }
}

export default {
    namespeced: true,
    state,
    getters,
    mutations,
    actions
}